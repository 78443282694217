class mockHelper {
  constructor() {
    this.models = {}
  }

  delay() {
    return Math.floor(Math.random() * (1000 - 500 + 1)) + 500
  }

  _handleProgress(onProgress) {
    if(typeof onProgress != 'function') return
    for(let i=1; i<=5; i++) {
      ((i) => {
        return setTimeout(() => {
          return onProgress({loaded: 20*i, total: 100})
        }, 100)
      })
    }
  }

  async success(data = null, onProgress = null) {
    const self = this
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        self._handleProgress(onProgress)
        resolve(data)
      }, this.delay())
    })
  }

  async error(error = null, onProgress = null) {
    const self = this
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        self._handleProgress(onProgress)
        reject(error)
      }, this.delay())
    })
  }

  async blob(onProgress = null) {
    const self = this
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        self._handleProgress(onProgress)
        resolve({
          data: new Blob(['foo', 'bar']),
          status: 200,
          xhr: {
            getResponseHeader(arg) {
              if(arg == 'content-type') return 'text/vnd.ms-excel; charset=UTF-8'
              if(arg == 'content-disposition') return 'attachment; filename="匯出.xlsx"'
            }
          }
        })
      }, this.delay())
    })
  }
}

export default new mockHelper()